import React from 'react'

const Proyecto = () => {
  return (
    <div className='bg-transparent p-8'>
        <div className='bg-transparent flex mt-16 mb-24 gap-10 w-full'>
            <div className='flex justify-center items-center w-2/4'>
                <img src="https://nestifyla.com/wp-content/uploads/2023/12/Home-equipo.png" alt="" className='w-full' />
            </div>
            <div className='w-2/4 flex flex-col justify-center gap-2'>
                <p className='text-left text-xs'>NUESTRA HISTORIA</p>
                <h2 className='text-left text-2xl	font-bold'>Buscamos una red de nómadas segura y próspera</h2>
                <p className='text-left text-base'>Queremos ser un espacio de encuentro y acercar a los nómadas digitales y freelancers en la región.</p>
                <div className='mt-8 mb-8'>
                    <img src="" alt="" />
                    <h4 className='text-left text-xl font-bold'>Nuestra visión</h4>
                    <p className='text-left text-base'>Ser el principal portal de referencia para nómadas y freelancers en Latam</p>
                </div>
                <div className=''>
                    <img src="" alt="" />
                    <h4 className='text-left text-xl font-bold'>Nuestra misión</h4>
                    <p className='text-left text-base'>Brindar soluciones y un espacio seguro a la vida de los freelancers y nómadas en Latam</p>
                </div>
            </div>
        </div>
        <div className='w-full flex gap-10 justify-between '>
            <div className='w-2/4 flex flex-col justify-center gap-4'>
                <p className='text-left text-xs'>CONOCE NUESTRA HISTORIA</p>
                <h2 className='text-left text-2xl font-bold'>Descubre quienes somos</h2>
                <p className='text-left text-base'> Creemos en la libertad de tiempo, movilidad y financiera para quienes trabajan en remoto.</p>
            </div>
            <div className='w-2/4 m-0 p-0 flex justify-center items-center'>
            <img src="http://nestifyla.com/wp-content/uploads/2023/11/Pitch-intecmar.jpg" alt="" className='w-full'/>
            </div>
        </div>
        <div className='w-full mt-20 flex justify-center'>
            <div className='w-2/4 flex flex-col gap-6'>    
                <p className='text-xs'>CONFÍAN EN NOSOTROS</p>
                <h2 className='text-2xl font-bold'>No paramos de crecer desde el día 1</h2>
                <p className='text-base'>Descubre las organizaciones que nos han acompañado desde el principio y nos ayudan a seguir creciendo en nuestro recorrido como startup.</p>
            </div> 
        </div>
        <div></div>
    </div>
  )
}

export default Proyecto