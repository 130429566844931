import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0 mt-6">
            <img className="h-16 w-44" src="https://nestifyla.com/wp-content/uploads/2024/01/Logo-para-la-web.png" alt="Logo" />
          </div>
          <div className="hidden sm:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a href="#hola" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Acerca De</a>
              <a href="#hola" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Equipo</a>
              <a href="#hola" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Demo</a>
              <a href="#hola" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Ticket</a>
              <a href="#hola" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">FAQS</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

//logo izquierda dimensiones a confirmar color blanco y menu con letras negras
//menu: acerca de...  equipo... demo... ticket... faqs... 