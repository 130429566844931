import React, { useState } from 'react';

const Dropdown = ({ pregunta, respuesta }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-400 ">
      <button
        className="flex justify-between items-center w-full focus:outline-none"
        onClick={toggleDropdown}
      >
        <h3 className="text-lg font-semibold">{pregunta}</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={`w-6 h-6 transition-transform ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        >
          <path
            fillRule="evenodd"
            d="M12 1.289l2.924 6.344L22 8.622l-5 4.876L19.28 22 12 17.78 4.72 22l1.356-8.502-5-4.876L9.076 7.633 12 1.289zm0 2.836L9.155 8.345 3.84 9.2l6.245 4.776L8.546 20l3.454-2.268L15.545 20l-1.54-5.024L20.16 9.2l-5.315-1.01L12 4.125z"
          />
        </svg>
      </button>
      {isOpen && <p className="mt-2">{respuesta}</p>}
    </div>
  );
};

export default Dropdown;
