import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Card = ({ image, name, role, profile }) => {
  return (
    <div className="card">
      <div className="card-front bg-transparent shadow-2xl p-4 rounded-md">
        <div className="card-content flex flex-col items-center justify-center h-72">
          <div className="card-image-wrapper w-44 h-44 rounded-lg">
            <img src={image} alt={name} className="card-image object-cover w-full h-full rounded-lg" />
          </div>
          <div className="card-info mt-2 text-center">
            <h3 className="card-name">{name}</h3>
            <p className="card-role">{role}</p>
            <SocialIcon network='linkedin' url={profile} bgColor='transparent' fgColor='black'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;


// sacar animacion de giro, colocando la info solo en una sola parte con fotos medianas y cuadradas

//estilachos de fuente para toda la pagina

//tipografia monserrat titutlos bold 700 y regular h2, y para los subtitulos h3  y parrafos 16px 1em